import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"
import LeftMenu from "./leftMenu"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import BostonGlobe from '../../images/logos/1057_Globe_150_Logo_BLK.png'
import KnightFoundation from '../../images/logos/KF_logo-stacked.png'

// import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav'
import '@trendmicro/react-sidenav/dist/react-sidenav.css'

function createData(division, firstPlace, secondPlace, thirdPlace) {
  return { division, firstPlace, secondPlace, thirdPlace };
}


const TodaysStudentsTomorrowsAmerica = () => { 
  
  const prizes = [
    createData('Grades 6-8', '$15,000', '$10,000', '$5,000'),
    createData('Grades 9-12', '$20,000', '$15,000', '$10,000'),
    createData('University [Undergraduate] / College', '$25,000', '$20,000', '$15,000'),
    
  ];


  return (
  <Layout>
    <SEO title="National Student Essay Competition - Today&rsquo;s Students, Tomorrow&rsquo;s America Project " />

    <div className="subpageContainer">

      <LeftMenu active="home" />
      <div className="body">

        {/* <h1 style={{marginTop: '55px'}}>Today&rsquo;s Students, Tomorrow&rsquo;s America Initiative: Grades Six through University</h1> */}
        <h4>Today&rsquo;s Students, Tomorrow&rsquo;s America Initiative Overview</h4>
        

        <p>Today&rsquo;s Students, Tomorrow&rsquo;s America [TSTA] is a small 501(c)(3) foundation whose stellar National Honorary Advisory Committee is chaired by Representative Jamie Raskin. Our Initiative aims to ensure that today&rsquo;s students do not perpetuate the disinformation and divisiveness now threatening our democracy in tomorrow&rsquo;s America. </p>

        <p>The TSTA Initiative focuses on an overlooked segment of our population vital to the preservation of the U.S.Constitution and Bill of Rights. With little, if any, opportunity to question for themselves the issues threatening our freedoms, many students, in middle school through high school, will be imprinted with distorted views of family, peers, and immediate influencers or remain as passive recipients of malignant propaganda.</p>
        <p>Deprived of civics education in most schools today, these students have neither the tools and information nor experience to discern truth in reporting from malicious misinformation. Even if the courts and election results resolve certain issues, disinformation will continue to metastasize among the uneducated and uninformed.</p>
        <p>In order to redress these issues, TSTA will launch a national essay competition in the fall of 2024 motivating students across the country - including those from historically marginalized communities - to begin the process of becoming informed, voting-age citizens. An extensive, in-depth public awareness campaign and generous cash competition awards are designed to attract even the most reluctant and/or uninterested. Student Essay Awards: Grades 6-8: 1st place, $15,000; 2nd place, $10,000; 3rd place, $5,000; Grades 9-12: 1st place, $20,000; 2nd place, $15,000; 3rd place, $10,000</p>
        <p>Essayists will be asked (1) to consider for themselves the freedoms guaranteed to each of them by the U.S. Constitution and Bill of Rights and (2) to submit an essay on how their own everyday lives are affected by and/or would be impacted by the erosion of those rights. The National Student Essay Competition, “Why Democracy Matters,” for students grades six through twelve will mark the first phase of the Today's Students, Tomorrow's America Initiative. Phase two will invite teachers to design innovative course curricula and Phase Three will invite administrators to design school-wide curriculum. All phases will have the same theme in an essay competition format.</p>

{/* 

      <div class="project-logos">
        <img src={BostonGlobe} width={'230px'} />
        <img src={KnightFoundation}  width={'180px'} />
      </div>
      <style>{`
        .project-logos {
          display: flex;
          flex-direction: columns;
          justify-content: space-around;
        }
        `}
      </style>


        <div>
          <p>Today&rsquo;s Students, Tomorrow&rsquo;s America Initiative [501(c)(3) grew out of two questions: (1) Whether our children and those who follow will perpetuate the divisions in our country today that are inflamed by disinformation and conspiracy theories, distrust of the press, and attempts to repress voting, privacy and curriculum freedoms, amongst others and (2) What can our Initiative do to empower young people to defuse those divisions while encouraging civil discourse and strengthening democratic processes.</p>
          <p>The Initiative focuses on energizing and engaging young people in America including those from historically marginalized communities, to participate in the democratic process on behalf of their guaranteed rights and freedoms.</p>
          <p>Several factors underscore the urgency of the Initiative&rsquo;s implementation: (1) The unevenness of the breadth and depth of civics education in the U.S. as detailed in The Bill of Obligations by Richard Haass, who concludes “There is a good deal of talk about the budget deficit. It may be that our civics deficit is of even greater consequence;” (2) Students today are a passive audience for whom even civic learning is on “the margins of their young school experience [Brookings] and who, if ignored, will simply perpetuate the current divisions in our society;”and (3) A December 2021 poll released by the Harvard University Kennedy School's Institute of Politics found that 52% of young people in the U.S. believe that the country's democracy is either "in trouble" or "a failed democracy;” only 7% said that democracy in the United States is “healthy.” </p>
          <p>The Initiative is designed to overcome those factors by motivating young people of all backgrounds and beliefs - whether alienated, discouraged, disinterested, imprinted by disinformation, or involved in the democratic process, etc. - to reflect upon (1) the everyday freedoms afforded them by the Constitution and Bill of Rights and how their lives would be affected if those freedoms were taken away from them; (2) how their participation as an open-minded and informed citizen can defend and preserve those freedoms; and (3) how contributing to public dialogue and the shaping of culture and policy will create a democracy reflecting their rights and freedoms.</p>
          <p>The Initiative will invite students, teachers and administrators to consider “why democracy matters” in a series of independently juried competitions offering substantial monetary awards. Three competitions [ essays for students; innovative lesson plans for teachers; and pioneering curriculum for administrators ] will run concurrently so that teacher and administrator participation reinforces that of students. A nationwide PR campaign is designed to maximize submissions as well as raise top-of-mind awareness of the need for civics education in our country.  </p>
          <p>Initiative awards, which make up more than half our non-profit&rsquo;s annual budget, range from $25,000 (first place, university), $20,000 (first place, grades 9-12) and $15,000 (first place, grades 6-8) with commensurate monetary awards for second and third place winners in each of the three categories.</p>
        </div> */}

{/* 
        <h3>Our Mission</h3>

        <p>Our determination to incentivize students to begin thinking about democracy for themselves is even more critical now. Our children have no role and no voice in today&rsquo;s debate about the legitimacy of our Constitution and the Bill of Rights. They are a passive audience for whom even civic learning is on “the margins of their young school experience [Brookings]” and who, if ignored, will simply perpetuate the current divisions in our society.</p>
        <p>A December 2021 poll released by the Harvard University Kennedy School's Institute of Politics found that 52% of young people in the U.S. believe that the country's democracy is either "in trouble" or "a failed democracy." Only 7% said that democracy in the United States is “healthy.”</p>
        <p>The Competition will provide students - who could be the future defenders of our Constitution and Bill of Rights - an opportunity to consider what it means to them individually when accusations of the press as “enemy of the people,” unfounded conspiracy theories, and threats to fundamental democratic values challenge their own freedom.</p>
        <p>In order to break through the clutter of attacks and counterattacks among our political leaders, the competition&rsquo;s award strategy aims to encourage children, grades six through university, to submit an essay on this topic. We have formed a lean non-profit [501(c)(3)], Today&rsquo;s Students, Tomorrow&rsquo;s America Project, that will focus its efforts on raising $300,000 in order to amplify the number of students writing about today&rsquo;s challenges to our democracy. </p>
        <p>Our goals, once funding is in place, are the following: (1) attract submissions from across the country, from every type of educational institution [ public, private, parochial, homeschooling, technical etc. ] and from students  of all backgrounds and political indoctrinations; (2) accelerate and intensify national coverage and support of the Today&rsquo;s Students, Tomorrow&rsquo;s Project; and (3) generate broad public recognition of the critical importance of civic education to a democracy.</p>

        <p>If our fundraising is successful, Today&rsquo;s Students, Tomorrow&rsquo;s America Project would like to present the winning National Student Essayists with the following awards:</p> */}


        <div>
        

        {/* <TableContainer component={Paper}>
          <Table sx={{ minWidth: 350, maxWidth: 600 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Categories</TableCell>
                <TableCell align="right">1st Place</TableCell>
                <TableCell align="right">2nd Place</TableCell>
                <TableCell align="right">3rd place</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prizes.map((row) => (
                <TableRow
                  key={row.division}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" style={{fontWeight: 'bold'}}>
                    {row.division}
                  </TableCell>
                  <TableCell align="right">{row.firstPlace}</TableCell>
                  <TableCell align="right">{row.secondPlace}</TableCell>
                  <TableCell align="right">{row.thirdPlace}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}

</div>

<div>

 <p>&nbsp;</p>
  <h4>Contact:</h4>
  
  <p>
    Mary Kay Office Lazarus, PhD<br />
    Executive Director<br />
    Today&rsquo;s Students, Tomorrow&rsquo;s America Initiative 501(c)(3)<br />
    mkl@mklpr.com. 801.328.889<br />
  </p>

</div>




        {/* <p>The McCarthey Family Foundation is funding the competition in collaboration with the Boston Globe as media sponsor. The Boston Globe Foundation will provide prize awards to the winning essayists and Westminster College in Salt Lake City will award a full tuition scholarship at the national awards ceremony to be held at the 15th Annual McCarthey Family Foundation Lecture Series: In Praise of Independent Journalism in the fall of 2020.</p>
        <p>Winning essayists in each category will receive $5,000 in prize money. Westminster College, a private liberal arts college in Salt Lake City with professional programs in an environment dedicated to civic engagement, will award a four-year scholarship, current total value of $152,000, to the winning essayist with the highest ranking by the competition jury among the three categories. Winner of the popular vote will receive a check for $1,000 from MKL Public Relations.</p> */}

        {/* 
        <p>We are now asking students from around the country to <Link to="/students-ask-newspapers-to-make-their-voices-heard">join us in addressing why a free press matters in our democracy</Link>.</p>
        <p>The competition aims not only to cultivate an informed and enlightened public but also to encourage an understanding of the First Amendment that strengthens freedom of the press and rebuilds trust. We believe there is no more important time than now to focus conversation and critical reflection on understanding the crucial relationships among the First Amendment, a free press, and the foundations of democracy.</p>
        <p>The McCarthey Family Foundation is funding the competition in collaboration with the Boston Globe as media sponsor. The Boston Globe Foundation will provide prize awards to the winning essayists and Westminster College in Salt Lake City will award a full tuition scholarship at the national awards ceremony to be held at the 15th Annual McCarthey Family Foundation Lecture Series: In Praise of Independent Journalism in the fall of 2020. </p>
        <p>Students in grades six through eight, in grades nine through twelve, and those at universities and colleges across the country are invited to submit essays examining the state of freedom of the press in the United States today in light of the First Amendment to the US Constitution.</p>
        <p>Winning essayists in each category will receive $5,000 in prize money. Westminster College, a private liberal arts college with professional programs in an environment dedicated to civic engagement, will award a four-year scholarship, currently valued at $38,000 a year, to the winning essayist with the highest ranking by the competition jury among the three categories. Call-for-entries and scholarships details will be announced in February 2020. <Link to="/essay-timeline">View the competition timeline.</Link></p>
         */}

    </div>
    
        </div>


  </Layout>
)}

export default TodaysStudentsTomorrowsAmerica
